<template>
    <div class="subject-card" :class="{ selected }" @click="$emit('toggleSelect')">
        <div>
            <div class="name">{{ subject.name }}</div>
            <div class="branch_name">{{ subject.branch_name.branchScience.name }}</div>
        </div>
        <div class="chart">
            <canvas ref="canvas" width="100" height="100"></canvas>
        </div>

        <div class="img_content">
            <div class="icon align-stretch">
                <img
                    v-if="subject.isFrontir == '1'"
                    src="../assets/img/fire.svg"
                    alt="Subject is frontier"
                />
                <img v-else src="../assets/img/white.svg" alt="Subject in promising direction" />
            </div>
        </div>
    </div>
</template>

<script>
import { Chart, registerables } from "chart.js";
import axios from "axios";
import { httpClient } from "../http-client";

Chart.register(...registerables);

export default {
    name: "SubjectCard",
    props: {
        subject: {
            type: Object
        },
        selected: {
            type: Boolean,
            required: true
        }
    },
    async mounted() {
        const labels = ["2017", "2018", "2019", "2020"];

        const articlesPromise = axios
            .get(
                `https://frontier-analytics.kovalev.team/year-articles?query=${this.subject.name}&start_year=2017&end_year=2020`
            )
            .then(response => response.data.data)
            .then(data => ({
                data: data.map((d, index) => ({ y: d.count, x: labels[index] }))
            }));

        const moneyPromise = httpClient()
            .post("/subject/money", [this.subject.name])
            .then(response => response.data)
            .then(money => {
                if (money.length === 0) {
                    return [];
                }

                money.forEach(m => (m.money = m.money.filter(r => r.year !== "2021")));
                return money[0].money;
            })
            .then(money => ({
                data: money.map((r, index) => ({
                    y: r.amount,
                    x: labels[index]
                }))
            }));

        const patentsPromises = [];

        labels.forEach(year => {
            const promise = httpClient()
                .get(
                    `/patents/findPatents?start=${year}0101&end=${year}1231&key=${this.subject.name}`
                )
                .then(response => ({
                    count: response.data.count,
                    year
                }));

            patentsPromises.push(promise);
        });

        const result = await Promise.all(patentsPromises);
        const patents = {
            data: result.map((d, index) => ({ y: d.count, x: labels[index] }))
        };

        const [articles, money] = await Promise.all([articlesPromise, moneyPromise]);

        articles.data.forEach((a, i) => {
            a.y =
                0.6 * parseInt(a.y) +
                0.8 * (money.data[i]?.y ? parseInt(money.data[i].y) : 0) +
                0.2 * patents.data[i].y;
        });

        if (articles.data[3].y < articles.data[2].y && articles.data[2].y < articles.data[1].y) {
            articles.borderColor = "#F87171";
        } else if (articles.data[3].y < articles.data[2].y) {
            articles.borderColor = "#FBBF24";
        } else {
            articles.borderColor = "#34D399";
        }

        const data = {
            labels,
            datasets: [articles]
        };
        const ctx = this.$refs.canvas;
        new Chart(ctx, {
            type: "line",
            data: data,
            options: {
                plugins: {
                    legend: { display: false },
                    tooltip: { enabled: false }
                },
                animation: false,
                scales: {
                    x: { display: false },
                    y: { display: false }
                }
            }
        });
    }
};
</script>

<style scoped lang="scss">
.subject-card {
    border: 1px solid #d1d5db;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 15px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    user-select: none;

    position: relative;

    &.selected {
        background-color: #d1fae5;
        border-color: #10b981;
        color: #047857;
    }
}
.name {
    min-width: 400px;
    font-size: 20px;
    font-weight: normal;
}
.icon {
    position: absolute;
    top: 10px;
    right: 10px;
}
.branch_name {
    opacity: 0.5;
    padding-top: 6px;
}
</style>
